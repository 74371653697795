import React from "react"
import { Link } from "gatsby"
// Style Imports
import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"

const page500 = () => (
    <Layout addedClass="page--error page--500">
        <Seo title="Server Error | Lupkynis.com" description="" robots="noindex" canonicalURL="https://www.lupkynis.com/500" />
        <Hero addedClass="bg-light-green hero--string">
            <div className="col-wrapper">
                <div className="container">
                    <div className="hero__content">
                        <h1 className="heading heading--accent">OOPS!</h1>
                        <h2>Something went wrong</h2>
                        <p>Sorry! We're experiencing technical difficulties right now. Please try again in a few hours.</p>
                        <Link to="/" className="button">Return to home</Link>
                    </div>
                    <div className="hero__image">
                        <Image data={{desktopSrc: 'hero-warning.png', mobileSrc: 'hero-warning--mobile.png'}} />
                    </div>
                </div>
            </div>
        </Hero>
    </Layout>
)

export default page500